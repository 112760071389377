html, body {
    height: 100%;
    margin: 0;
    /* overflow: hidden; */
  }
.full-screen-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    border: 3px;
    overflow: hidden;
    box-sizing: border-box;
    /* position: "relative" */
}
  
.full-screen-image img {
    max-width: 100%;
    height: auto;
    border: 3px;
    box-sizing: border-box;
    display: block;
}

.full-screen-image video {
    width:100%;
    max-width: 100%;
    height: auto;
    border: 3px;
    box-sizing: border-box;
    display: block;
}
.displayText {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    transform: translate(0px, -134px);
    color: white;
}
.caption{
    width: 100%;
    margin: 0 auto;
    text-align: center;
   
    color: white;
}
.darkEmailInputStyle{
    background-color: #212529;
    color: white;
    border: 1px solid white;
    border-radius: 4px;
    padding: 10px;
    width: -webkit-fill-available;
    outline: none;
}